import React from 'react';
import EmailCourseSignupForm from './EmailCourseSignupForm';

export default () => (
  <div className="bg-gray-200 md:rounded-xl p-8 my-8 font-sans text-lg">
    <h3 className="mt-0 text-center uppercase text-primary-600">
      Free Email Course
    </h3>
    <h2 className="mt-0 text-center">
      How to transition your team to a serverless-first mindset
    </h2>
    <p>In this 5-day email course, you’ll learn:</p>
    <ul>
      <li>Lesson 1: Why serverless is inevitable</li>
      <li>
        Lesson 2: How to identify a candidate project for your first serverless
        application
      </li>
      <li>Lesson 3: How to compose the building blocks that AWS provides</li>
      <li>
        Lesson 4: Common mistakes to avoid when building your first serverless
        application
      </li>
      <li>Lesson 5: How to break ground on your first serverless project</li>
    </ul>
    <EmailCourseSignupForm />
  </div>
);
