import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import _sortBy from 'lodash/sortBy';
import _intersection from 'lodash/intersection';
import { getPostsFromEdges } from './PostListing/PostListing';

const RelatedPosts = ({ postEdges }) => {
  return (
    <ul className="similar-articles">
      {postEdges.map((post) => (
        <li key={post.slug}>
          <Link to={post.path}>
            {post.title}
          </Link>

        </li>
      ))}
    </ul>
  )
};

const rankPostsByCategoriesAndTags = (posts, { categories, tags, limit = 5 }) => {
  const ranked = posts.map((post) => {
    const rank = _intersection(post.categories, categories).length + _intersection(post.tags, tags).length
    return { rank, post };
  });
  return _sortBy(ranked, 'rank')
    .reverse()
    .slice(0, limit)
    .map(r => r.post);
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarArticles {
        posts: allMarkdownRemark(
          sort: {
            order: DESC,
            fields: [frontmatter___date]
          }
          filter: {
            frontmatter: {
              layout: {eq: "post"}
            }
          }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
                date
              }
              excerpt
              timeToRead
              frontmatter {
                title
                description
                tags
                categories
                date
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { postNode: {frontmatter: { tags, categories }, fields: { slug } }} = props;

      const allPosts = getPostsFromEdges(data.posts.edges).filter(({path}) => path !== slug); // exclude current post

      const topPostEdges = rankPostsByCategoriesAndTags(allPosts, { categories, tags, limit: 3 });
      return (
        <RelatedPosts
          postEdges={topPostEdges}
        />
      )
    }}
  />
)
