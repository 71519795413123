import React from 'react';

const REDUNDANT_TAGS = [
  'serverless',
]

export default ({ tags = [] }) => {
  // Remove redundant tags
  const filteredTags = tags.filter(t => !REDUNDANT_TAGS.includes(t.toLowerCase()));
  return (
    <div className="flex flex-wrap text-sm">
      {filteredTags && filteredTags.map((tag) => (
        <span key={tag}>
          <span className="inline-block bg-gray-100 px-1 rounded whitespace-normal ml-1 my-1 text-gray-600">{tag}</span>
        </span>
      ))}
    </div>
  );

}
