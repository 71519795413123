/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import moment from 'moment';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from './layout';
import UserInfo from '../components/UserInfo/UserInfo';
import PostTags from '../components/PostTags/PostTags';
import RelatedPosts from '../components/RelatedPosts';
import MetaTags from '../components/MetaTags';
import EmailCourseSignupBox from '../components/EmailCourseSignup/EmailCourseSignupBox';
import config from '../../data/SiteConfig';
import BlogPostSidebar from '../components/BlogPostSidebar';
import './b16-tomorrow-dark.css';

export default function PostTemplate({ data, pageContext }) {
  const { slug } = pageContext;
  const postNode = data.markdownRemark;
  const { frontmatter, timeToRead } = postNode;
  const publishDate = postNode.fields.date;
  const modifiedDate = frontmatter.last_modified_at;
  const cover = frontmatter.image_url;
  const showCover = cover && !frontmatter.hide_top_image;
  const coverImageFluid = cover ? cover.childImageSharp.fluid : null;
  const twitterCardType = frontmatter.twitter_card || 'summary_large_image';
  const { image_credit } = frontmatter;
  if (!frontmatter.id) {
    frontmatter.id = slug;
  }
  if (!frontmatter.category_id) {
    frontmatter.category_id = config.postDefaultCategoryID;
  }

  return (
    <Layout>
      <MetaTags
        path={slug}
        title={frontmatter.title}
        description={frontmatter.description}
        image={coverImageFluid ? coverImageFluid.src : null}
        twitterCardType={twitterCardType}
        isBlogPost="true"
        publishedDate={publishDate}
      />
      <div className="grid grid-cols-2 lg:grid-cols-3 px-4 md:px-8 lg:gap-16 max-w-7xl lg:mx-auto">
        <article className="blog-post max-w-3xl mx-auto col-span-2">
          <header className="mb-4">
            {showCover && (
              <div className="-mx-4 md:-mx-8">
                <Img
                  fluid={coverImageFluid}
                  className="w-full"
                  alt={frontmatter.coverAlt || frontmatter.title}
                />
                {image_credit && (
                  <div className="pl-2 font-sans text-gray-500 text-sm text-right px-2 py-1">
                    {image_credit}
                  </div>
                )}
              </div>
            )}
            <h1 className="mt-5 mb-2 font-extrabold">{frontmatter.title}</h1>
            <div className="font-sans text-base text-gray-700 my-2 grid md:grid-cols-3">
              <span
                className="flex items-center"
                itemprop="author"
                itemscope=""
                itemtype="http://schema.org/Person"
              >
                <meta itemprop="url" content="https://serverlessfirst.com" />
                <Img
                  className="rounded-full mr-2 border-2 border-blue-100"
                  fixed={data.paulImage.childImageSharp.fixed}
                />
                <span itemprop="name" className="inline-block align-middle">
                  Paul Swail
                </span>
                <span className="reading-time">・{timeToRead} min read</span>
              </span>
              <div className="col-span-2 py-2 ">
                <PostTags tags={frontmatter.tags || frontmatter.categories} />
              </div>
            </div>
          </header>

          <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
          <section className="mb-4 font-sans text-sm text-gray-500 text-right">
            <span>
              Originally published{' '}
              <time
                itemprop="datePublished"
                datetime={moment(publishDate).format('YYYY-MM-DD')}
              >
                {moment(publishDate).format('MMMM D, YYYY')}
              </time>
              .
            </span>
            {modifiedDate && (
              <span className="pl-2">
                Last updated{' '}
                <time
                  itemprop="dateModified"
                  datetime={moment(modifiedDate).format('YYYY-MM-DD')}
                >
                  {moment(modifiedDate).format('MMMM D, YYYY')}
                </time>
                .
              </span>
            )}
            {/* <SocialLinks postPath={slug} postNode={postNode} /> */}
          </section>
          <section>
            <h3>Other articles you might enjoy:</h3>
            <RelatedPosts postNode={postNode} />
          </section>
          <UserInfo config={config} />
          <div className="-mx-4">
            <EmailCourseSignupBox postNode={postNode} />
          </div>
          {/* <Footer config={config} /> */}
        </article>
        <div className="col-span-3 lg:col-span-1 mx-auto">
          <BlogPostSidebar />
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        description
        date
        last_modified_at
        category
        categories
        tags
        image_url {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_credit
        hide_top_image
      }
      fields {
        slug
        date
      }
    }
    paulImage: file(
      relativePath: { eq: "paulswail_2021-09_sideprofile.jpeg" }
    ) {
      childImageSharp {
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
